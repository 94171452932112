import "./App.css";
import * as React from "react";
import { useRef } from "react";
import Xmas from "./Xmas";
import LZUTF8 from "lzutf8";

/* MUI Stuff */
import { Container, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { Box, Paper } from "@mui/material";
import { Card, CardContent, IconButton, Slider } from "@mui/material";
import { Grid } from "@mui/material";

import Stack from "@mui/material/Stack";
import PauseRounded from "@mui/icons-material/PauseRounded";
import PlayArrowRounded from "@mui/icons-material/PlayArrowRounded";
import FastForwardRounded from "@mui/icons-material/FastForwardRounded";
import FastRewindRounded from "@mui/icons-material/FastRewindRounded";
import VolumeUpRounded from "@mui/icons-material/VolumeUpRounded";
import VolumeDownRounded from "@mui/icons-material/VolumeDownRounded";

// styled
import { styled } from "@mui/material/styles";

/* Icons */
import { FaDiscord } from "react-icons/fa";
import tebexLogo from "./tebex-logo.svg";

function hexToRGBA(hex, alpha = 1) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

function ChangeLog(Config) {
  // promise await for Config.json
  new Promise((resolve, reject) => {
    setTimeout(() => resolve(Config), 1000);
  });

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <ThemeProvider theme={theme}>
      {Config.displayChangeLog && (
        <Box
          sx={{
            position: "fixed",
            top: isSmallScreen ? "10%" : 0,
            right: isSmallScreen ? 0 : 0,
            width: isSmallScreen ? "100%" : "33%",
            height: isSmallScreen ? "80%" : "100%",
            padding: "1.0rem",
            opacity: Config.changeLogOpacity,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "none",
          }}
        >
          <Paper
            elevation={3}
            className="custom-scrollbar"
            sx={{
              padding: "0.1rem",
              overflow: "auto",
              width: "auto",
              height: "auto",
              maxHeight: "100%",
              backgroundColor: "transparent",
              border: "none",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  backgroundColor: hexToRGBA(
                    Config.theme.palette.card.main,
                    0.8
                  ),
                  display: "inline-block",
                  padding: "0.5rem",
                  borderRadius: "0.66rem",
                }}
              >
                Change Log
              </Typography>
            </div>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{ border: "none" }}
            >
              {Config.changelog.map((item, index) => (
                <Grid item xs={4} key={index}>
                  <Card
                    className="changeCard"
                    sx={{
                      backgroundColor:
                        index % 3 === 0
                          ? "#d81e05"
                          : index % 3 === 1
                          ? "#0277bd"
                          : "#808080",
                      borderRadius: "6px",
                      marginTop: "0.1rem",
                      border: "1px solid #808080",
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="h6"
                        sx={{ color: theme.palette.card.title }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ color: theme.palette.card.body }}
                      >
                        {item.body}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Box>
      )}
      {Config.displayRules && (
        <Box
          sx={{
            position: "fixed",
            top: isSmallScreen ? "10%" : 0,
            left: isSmallScreen ? 0 : 0,
            width: isSmallScreen ? "100%" : "33%",
            height: isSmallScreen ? "80%" : "100%",
            padding: "1.0rem",
            opacity: Config.rulesOpacity,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "none",
          }}
        >
          <Paper
            elevation={3}
            className="custom-scrollbar"
            sx={{
              padding: "0.1rem",
              overflow: "auto",
              width: "auto",
              height: "auto",
              maxHeight: "100%",
              backgroundColor: "transparent",
              border: "none",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  backgroundColor: hexToRGBA(
                    Config.theme.palette.card.main,
                    0.8
                  ),
                  display: "inline-block",
                  padding: "0.5rem",
                  borderRadius: "0.66rem",
                }}
              >
                Rules
              </Typography>
            </div>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{ border: "none" }}
            >
              {Config.rules.map((item, index) => (
                <Grid item xs={4} key={index}>
                  <Card
                    className="ruleCard"
                    sx={{
                      backgroundColor:
                        index % 3 === 0
                          ? "#d81e05"
                          : index % 3 === 1
                          ? "#0277bd"
                          : "#808080",
                      borderRadius: "6px",
                      marginTop: "0.1rem",
                      border: "1px solid #808080",
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="h6"
                        sx={{ color: theme.palette.card.title }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ color: theme.palette.card.body }}
                      >
                        {item.body}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Box>
      )}
    </ThemeProvider>
  );
}

function DisplayServerDiscord({ Config, theme }) {
  if (
    !Config.server.discordAbovePlayer &&
    Config.server.discord &&
    Config.server.displayDiscord
  ) {
    return (
      <Grid item>
        <Typography
          variant="h5"
          sx={{
            color: theme.palette.text.primary,
            fontFamily: "IBM Plex Sans",
            opacity: Config.server.discordOpacity,
            fontWeight: "900",
            marginTop: "0.5rem",
            backgroundColor: "#7289da",
            borderRadius: "10px",
            padding: "6px",
          }}
        >
          <span style={{ display: "flex", alignItems: "center" }}>
            <FaDiscord style={{ marginRight: "0.5rem" }} />
            {Config.server.discord}
          </span>
        </Typography>
      </Grid>
    );
  }
}

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#0A1929",
      },
      secondary: {
        main: "#3399FF",
      },
      text: {
        primary: "#FFFFFF",
        secondary: "#3399FF",
      },
      card: {
        main: "#222222",
        title: "#FFFFFF",
        body: "#FFFFFF",
        button: "#0A1929",
      },
      mode: "dark",
    },
    typography: {
      fontFamily: ["IBM Plex Sans, sans-serif"],
    },
  });
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [configLoaded, setConfigLoaded] = React.useState(false);
  const [Config, setConfig] = React.useState({});

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const compressedConfigParam = urlParams.get("config");

    console.log(
      `Compressed Config Param: (${typeof compressedConfigParam}) ${compressedConfigParam}`
    );

    if (!compressedConfigParam) {
      console.error("No 'config' query parameter found");
      return;
    }

    try {
      const array = compressedConfigParam.split(",").map(Number);
      const buffer = new Uint8Array(array);
      const configParam = LZUTF8.decompress(buffer);

      if (configParam) {
        const loadedConfig = JSON.parse(configParam);
        console.log(`Loaded Config: (${typeof loadedConfig})`, loadedConfig);
        setConfig(JSON.parse(JSON.stringify(loadedConfig)));
        setConfigLoaded(true);
      }
    } catch (error) {
      console.error("Failed to process 'config' query parameter:", error);
    }
  }, []);

  React.useEffect(() => {
    if (
      Config &&
      Config.theme &&
      Config.theme.palette &&
      Config.theme.palette.primary
    ) {
      document.body.style.backgroundColor = Config.theme.palette.primary.main;

      const cursorinner = document.querySelector(".cursor2");
      if (cursorinner) {
        document.addEventListener("mousemove", function (e) {
          const x = e.clientX;
          const y = e.clientY;
          cursorinner.style.left = `${x}px`;
          cursorinner.style.top = `${y}px`;
        });

        document.addEventListener("mousedown", function () {
          cursorinner.classList.add("cursorinnerhover");
        });

        document.addEventListener("mouseup", function () {
          cursorinner.classList.remove("cursorinnerhover");
        });
      } else {
        console.warn(".cursor2 element not found in DOM");
      }
    }
  }, [Config?.theme?.palette?.primary?.main]);

  if (!configLoaded) {
    return <div>Loading...</div>;
  } else {
    console.log(
      "Config:",
      JSON.stringify(Config.server, null, 2),
      "discordAbovePlayer?",
      Config.server.discordAbovePlayer
    );

    return (
      <ThemeProvider theme={theme}>
        <div className="App">
          {/* Full Screen Container */}
          <Container maxWidth="xl" sx={{ height: "100vh" }}>
            {/* Center of screen Logo: Config.server.logo */}
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{
                height: "100%",
                marginBottom: isSmallScreen ? "150px" : 0,
              }}
              spacing={1}
            >
              {Config.server.logo && Config.server.displayLogo && (
                <Grid item>
                  {Config.server.roundLogo && (
                    <img
                      src={Config.server.logo}
                      alt="Logo"
                      style={{
                        height: "15vh",
                        width: "auto",
                        opacity: Config.server.logoOpacity,
                        borderRadius: "50%",
                      }}
                    />
                  )}
                  {!Config.server.roundLogo && (
                    <img
                      src={Config.server.logo}
                      alt="Logo"
                      style={{
                        height: "15vh",
                        width: "auto",
                        opacity: Config.server.logoOpacity,
                        borderRadius: "1rem",
                      }}
                    />
                  )}
                </Grid>
              )}

              {Config.server.name && Config.server.displayName && (
                <Grid item>
                  <Typography
                    variant="h2"
                    sx={{
                      color: theme.palette.text.primary,
                      fontWeight: "bold",
                      fontFamily: "AudioWide",
                      opacity: Config.server.nameOpacity,
                    }}
                  >
                    {Config.server.name}
                  </Typography>
                </Grid>
              )}

              <DisplayServerDiscord Config={Config} theme={theme} />

              {Config.server.tebex && Config.server.displayTebex && (
                // tebexLogo
                <Grid item>
                  <Typography
                    variant="h5"
                    sx={{
                      color: theme.palette.text.primary,
                      fontFamily: "IBM Plex Sans",
                      opacity: Config.server.tebexOpacity,
                      marginTop: "0.5rem",
                      backgroundColor: `rgba(33, 33, 33, ${Config.server.tebexOpacity})`,
                      borderRadius: "10px",
                      padding: "6px",
                    }}
                  >
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={tebexLogo}
                        alt="Tebex"
                        style={{
                          height: "0.75rem",
                          width: "auto",
                          margin: "0.5rem",
                        }}
                      />
                      {Config.server.tebex}
                    </span>
                  </Typography>
                </Grid>
              )}
            </Grid>
            <ChangeLog Config={Config} />
            <MusicPlayerSlider Config={Config} />

            <video
              autoPlay
              muted
              loop
              id="myVideo"
              style={{
                position: "fixed",
                right: 0,
                bottom: 0,
                minWidth: "100%",
                minHeight: "100%",
                width: "auto",
                height: "auto",
                zIndex: -1,
              }}
            >
              <source src={Config.video} type="video/mp4" />
            </video>
            {/* <audio id='audio' autoPlay controls/> */}
          </Container>
        </div>

        {configLoaded && Config.xmas && <Xmas />}
      </ThemeProvider>
    );
  }
}

/* New Audio Player */

const Widget = styled("div")(({ theme }) => ({
  padding: 16,
  borderRadius: 16,
  maxWidth: "100%",
  margin: "auto",
  position: "relative",
  zIndex: 1,
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(0,0,0,0.6)" : "rgba(255,255,255,0.4)",
  backdropFilter: "blur(40px)",
  color: "#f5f5f5",
}));

const CoverImage = styled("div")({
  width: 100,
  height: 100,
  objectFit: "cover",
  overflow: "hidden",
  flexShrink: 0,
  borderRadius: 8,
  backgroundColor: "rgba(0,0,0,0.08)",
  "& > img": {
    width: "100%",
  },
  color: "#f5f5f5",
});

const TinyText = styled(Typography)({
  fontSize: "0.75rem",
  opacity: 0.38,
  fontWeight: 500,
  letterSpacing: 0.2,
  color: "#f5f5f5",
});

function MusicPlayerSlider({ Config }) {
  const theme = useTheme();

  const [song, setSong] = React.useState({
    title: "",
    artist: "",
    album: "",
    cover: "",
    audio: "",
  });

  const audioRef = useRef(null);

  const duration = audioRef.current?.duration || 0;
  const [position, setPosition] = React.useState(0);
  const [paused, setPaused] = React.useState(false);
  const [volume, setVolume] = React.useState(10);
  const [playedSongs, setPlayedSongs] = React.useState([]);

  async function loadRandomSong() {
    try {
      const URL =
        "https://cors.streamclash.dev/?url=" +
        encodeURIComponent(Config.playlist);
      const response = await fetch(URL);
      const data = await response.json();
      console.log(data);
      let randomSong = data.data[Math.floor(Math.random() * data.data.length)];

      while (playedSongs.includes(randomSong.id)) {
        randomSong = data.data[Math.floor(Math.random() * data.data.length)];
      }

      setSong({
        title: randomSong.title,
        artist: randomSong.artist.name,
        album: randomSong.album.title,
        cover: randomSong.album.cover_medium,
        audio: randomSong.preview,
      });

      audioRef.current.src = randomSong.preview;
      audioRef.current.volume = volume / 100;
      audioRef.current.play();

      setPlayedSongs([...playedSongs, randomSong.id]);

      // remove the oldest song from the list
      if (playedSongs.length > 5) {
        playedSongs.shift();
      }
    } catch (error) {
      console.error(error);
    }
  }

  React.useEffect(() => {
    loadRandomSong();
    audioRef.current.addEventListener("timeupdate", () => {
      setPosition(audioRef.current.currentTime);
      if (audioRef.current.currentTime === audioRef.current.duration) {
        loadRandomSong();
      }
    });
  }, []);

  const handlePlayPause = () => {
    if (paused) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
    setPaused(!paused);
  };

  const handleVolumeChange = (_, newValue) => {
    setVolume(newValue);
    audioRef.current.volume = newValue / 100;
  };

  const handleNextSong = () => {
    loadRandomSong();
  };

  const handlePrevSong = () => {
    audioRef.current.currentTime = 0;
  };

  const handleSeek = (_, newValue) => {
    setPosition(newValue);
    audioRef.current.currentTime = newValue;
  };

  function formatDuration(value) {
    const minute = Math.floor(value / 60);
    const secondLeft = Math.floor(value - minute * 60);
    return `${minute.toString().padStart(2, "0")}:${secondLeft
      .toString()
      .padStart(2, "0")}`;
  }

  const mainIconColor = theme.palette.mode === "dark" ? "#fff" : "#000";
  const lightIconColor =
    theme.palette.mode === "dark" ? "rgba(255,255,255,0.4)" : "rgba(0,0,0,0.4)";

  return (
    <>
      <audio
        ref={audioRef}
        onTimeUpdate={(e) => setPosition(e.target.currentTime)}
      >
        <source src={song.audio} type="audio/mp3" />
      </audio>
      <Box
        sx={{
          position: "fixed",
          bottom: 16,
          left: 16,
          width: "100%",
          overflow: "hidden",
          maxWidth: 343,
        }}
      >
        {Config.server.discordAbovePlayer &&
          Config.server.discord &&
          Config.server.displayDiscord && (
            <span
              style={{
                display: "flex",
                alignItems: "center",
                color: theme.palette.text.primary,
                fontFamily: "IBM Plex Sans",
                opacity: Config.server.discordOpacity,
                fontWeight: "900",
                backgroundColor: "#7289da",
                borderRadius: "0.70em",
                padding: "0.33rem",
                position: "relative",
                width: "fit-content",
                left: "25%",
                marginBottom: "0.5rem",
                zIndex: 1,
              }}
            >
              <FaDiscord style={{ marginRight: "0.33rem" }} />
              {Config.server.discord}
            </span>
          )}
        <Widget>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CoverImage>
              <img alt="loading" src={song.cover} />
            </CoverImage>
            <Box sx={{ ml: 1.5, minWidth: 0 }}>
              <Typography variant="caption" color="text.main" fontWeight={500}>
                {song.artist}
              </Typography>
              <Typography noWrap>{song.title}</Typography>
              <Typography noWrap letterSpacing={-0.25}>
                {song.album}
              </Typography>
            </Box>
          </Box>
          <Slider
            aria-label="time-indicator"
            size="small"
            value={position}
            min={0}
            step={1}
            max={duration}
            onChange={(_, value) => handleSeek(_, value)}
            sx={{
              color:
                theme.palette.mode === "dark" ? "#fff" : "rgba(0,0,0,0.87)",
              height: 4,
              "& .MuiSlider-thumb": {
                width: 8,
                height: 8,
                transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
                "&:before": {
                  boxShadow: "0 2px 12px 0 rgba(0,0,0,0.4)",
                },
                "&:hover, &.Mui-focusVisible": {
                  boxShadow: `0px 0px 0px 8px ${
                    theme.palette.mode === "dark"
                      ? "rgb(255 255 255 / 16%)"
                      : "rgb(0 0 0 / 16%)"
                  }`,
                },
                "&.Mui-active": {
                  width: 20,
                  height: 20,
                },
              },
              "& .MuiSlider-rail": {
                opacity: 0.28,
              },
            }}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: -2,
            }}
          >
            <TinyText>{formatDuration(position)}</TinyText>
            <TinyText>-{formatDuration(duration - position)}</TinyText>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: -1,
            }}
          >
            <IconButton
              aria-label="previous song"
              onClick={() => handlePrevSong()}
            >
              <FastRewindRounded fontSize="large" htmlColor={mainIconColor} />
            </IconButton>
            <IconButton
              aria-label={paused ? "play" : "pause"}
              onClick={() => handlePlayPause()}
            >
              {paused ? (
                <PlayArrowRounded
                  sx={{ fontSize: "3rem" }}
                  htmlColor={mainIconColor}
                />
              ) : (
                <PauseRounded
                  sx={{ fontSize: "3rem" }}
                  htmlColor={mainIconColor}
                />
              )}
            </IconButton>
            <IconButton aria-label="next song" onClick={() => handleNextSong()}>
              <FastForwardRounded fontSize="large" htmlColor={mainIconColor} />
            </IconButton>
          </Box>
          <Stack
            spacing={2}
            direction="row"
            sx={{ mb: 1, px: 1 }}
            alignItems="center"
          >
            <VolumeDownRounded htmlColor={lightIconColor} />
            <Slider
              aria-label="Volume"
              value={volume}
              onChange={handleVolumeChange}
              sx={{
                color:
                  theme.palette.mode === "dark" ? "#fff" : "rgba(0,0,0,0.87)",
                "& .MuiSlider-track": {
                  border: "none",
                },
                "& .MuiSlider-thumb": {
                  width: 24,
                  height: 24,
                  backgroundColor: "#fff",
                  "&:before": {
                    boxShadow: "0 4px 8px rgba(0,0,0,0.4)",
                  },
                  "&:hover, &.Mui-focusVisible, &.Mui-active": {
                    boxShadow: "none",
                  },
                },
              }}
            />
            <VolumeUpRounded htmlColor={lightIconColor} />
          </Stack>
        </Widget>
      </Box>
    </>
  );
}

/* END OF NEW AUDIO PLAYER */

export default App;
